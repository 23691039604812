<template>
  <div class="col-auto px-0 px-md-1 relative" @mouseover="isHover = true" @mouseout="isHover = false" v-if="siteDetails">
    <SwitchLocationIconRound v-if="siteDetails.currentSite.icon" v-touch="toggleSwitcher" :name="siteDetails.currentSite.display" :bg="backgroundColor">
      <img v-if="siteDetails.currentSite.icon" :src="require('@/assets/images/' + siteDetails.currentSite.icon + '.png')" class="img-fluid round_image p-0 px-lg-2">
      <!-- <Image :imageName="siteDetails.currentSite.icon" theClass="img-fluid round_image p-0 px-lg-2" /> -->
    </SwitchLocationIconRound>
    <SwitchLocationAction v-show="isHover" :otherSite="siteDetails.otherSite" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    SwitchLocationIconRound: defineAsyncComponent(() => import('./SwitchLocationIconRound.vue')),
    SwitchLocationAction: defineAsyncComponent(() => import('./SwitchLocationAction.vue'))
  },
  props: ['backgroundColor'],
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    ...mapGetters([
      'headOffice', 'internationalBranches', 'isMobile'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    otherUrl () {
      return process.env.VUE_APP_OTHER_URL_BASE
    },
    siteDetails () {
      const details = {
        currentSite: {
          name: '',
          display: '',
          icon: '',
          currency: ''
        },
        otherSite: {
          name: '',
          display: '',
          icon: '',
          currency: '',
          url: ''
        }
      }
      if (this.isUK) {
        details.currentSite.name = 'United Kingdom'
        details.currentSite.display = 'GBP | £'
        details.currentSite.icon = 'uk_image'
        details.currentSite.currency = 'GBP (£)'
        details.otherSite.name = 'South Africa'
        details.otherSite.icon = 'za_image'
        details.otherSite.currency = 'ZAR (R)'
        details.otherSite.url = this.otherUrl
      } else {
        details.currentSite.name = 'South Africa'
        details.currentSite.display = 'ZAR | R'
        details.currentSite.icon = 'za_image'
        details.currentSite.currency = 'ZAR (R)'
        details.otherSite.name = 'United Kingdom'
        details.otherSite.icon = 'uk_image'
        details.otherSite.currency = 'GBP (£)'
        details.otherSite.url = this.otherUrl
      }
      return details
    }
  },
  methods: {
    toggleSwitcher () {
      console.log('TOHRHEHR')
      if (this.isHover) {
        this.isHover = false
      } else {
        this.isHover = true
      }
    }
  }
}

</script>
